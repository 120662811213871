import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Link,
  makeStyles,
  Theme,
  createStyles,
  Box,
  Hidden,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    navLink: {
      color: 'black',
      marginLeft: theme.spacing(2),
    },
    appBar: {
      zIndex: 11,
    },
  })
);

export const Navbar: React.FC = () => {
  const classes = useStyles();
  const [menuIsOpened, setMenuIsOpened] = useState(false);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Hidden xsDown>
            <img src="./logo.png" height="40" alt="logo" />
            <Typography variant="h6" className={classes.title}>
              Unstaged Comic
            </Typography>
            <nav>
              <Link href="/#/" className={classes.navLink}>
                Home
              </Link>
              <Link href="/#/about" className={classes.navLink}>
                About
              </Link>
            </nav>
          </Hidden>
          <Hidden smUp>
            <Box display="flex" style={{ width: '100%' }} justifyContent="space-between" alignItems="center">
              <img src="./logo_mobile.png" height="60" alt="logo" />
              <IconButton edge="start" aria-label="menu" onClick={() => setMenuIsOpened(!menuIsOpened)}>
                <MenuIcon />
              </IconButton>
            </Box>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Drawer
        style={{ width: 'auto', flexShrink: 0, zIndex: 10 }}
        open={menuIsOpened}
        onClose={() => setMenuIsOpened(!menuIsOpened)}
        anchor="top"
      >
        <Toolbar />
        <div style={{ overflow: 'auto' }}>
          <List onClick={() => setMenuIsOpened(!menuIsOpened)} onKeyDown={() => setMenuIsOpened(!menuIsOpened)}>
            <ListItem button component="a" href="/#/">
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component="a" href="/#/about">
              <ListItemText primary="About" />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </div>
  );
};
