import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { CardHeader, IconButton } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';

const useStyles = makeStyles({
  root: {
    maxWidth: 600,
  },
});

interface Props {
  imgSrc: string;
  title: string;
  uploadDate: string;
}

export const Strip: React.FC<Props> = ({ imgSrc, title, uploadDate }) => {
  const classes = useStyles();
  const stripName = title.substring(0, title.indexOf('.'));

  return (
    <Card className={classes.root}>
      <CardHeader title={stripName} />
      <CardMedia component="img" alt={title} style={{ maxHeight: '280' }} image={imgSrc} title={title} />
      <CardContent></CardContent>
      <CardActions>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};
