import React, { useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Strip } from './Strip';
import { getStrips, getStripObject } from '../api/storeApi';
import _ from 'lodash';

export type Strip = {
  path: string;
  title: string;
  date: string;
};

export const Comics: React.FC = () => {
  const [allImageRefs, setAllRefs] = React.useState<any>([]);
  const [allStrips, setAllStrips] = React.useState<Strip[]>([]);

  useEffect(() => {
    async function getAll() {
      getStrips().then(async (res) => {
        setAllRefs(res);
      });
    }

    getAll();
  }, []);

  useEffect(() => {
    async function setAll(): Promise<Strip[]> {
      const sortedImageRefs = _.orderBy(allImageRefs, ['name'], ['desc']);
      return Promise.all(sortedImageRefs.map(async (item: any) => getStripObject(item)));
    }

    setAll().then((res) => {
      setAllStrips(res);
    });
  }, [allImageRefs]);

  return (
    <Box display="flex" justifyContent="center" m={1} p={1}>
      <Grid container spacing={3}>
        {allStrips.map((c) => {
          return (
            <Grid key={c.title} item xs={12}>
              <Box display="flex" justifyContent="center">
                <Strip imgSrc={c.path} title={c.title} uploadDate={c.date} />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
