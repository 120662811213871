import React from 'react';
import './App.css';
import { Box, ThemeProvider, BottomNavigation } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { Navbar } from './modules/Navbar';
import red from '@material-ui/core/colors/red';
import { Comics } from './modules/Comics';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { About } from './modules/About';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#57BC90',
    },
    secondary: red,
  },
  typography: {
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Navbar />
        <Router>
          <div style={{ marginTop: '4em' }}>
            <Switch>
              <Route exact path="/" component={Comics} />
              <Route exact path="/about" component={About} />
            </Switch>
          </div>
        </Router>
        <BottomNavigation showLabels></BottomNavigation>
      </Box>
    </ThemeProvider>
  );
};

export default App;
