import React from 'react';
import { Box, Paper, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 5),
    maxWidth: 800,
  },
  paragraph: {
    padding: theme.spacing(2, 2),
  },
  license: {
    padding: theme.spacing(2, 2),
    maxWidth: 400,
  },
}));

export const About: React.FC = () => {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="center" m={1} p={1}>
      <Paper className={classes.root}>
        <Typography variant="h5" component="h3" className={classes.paragraph}>
          About
        </Typography>

        <Typography component="p" className={classes.paragraph}>
          Hello World! We are a couple of software engineers who enjoy the quirky tech culture we share. We've built
          this page to share comic strips that we create for fun. We hope you enjoy it.
        </Typography>

        <Typography component="p" className={classes.paragraph}>
          Any and all content is a work of fiction. Names, characters, companies and conversations are either products
          of our own imagination or used in a fictitous manner. Any resemblance to actual people, living or dead, or
          actual events is purely coincidental.
        </Typography>

        <Box display="flex" justifyContent="center" m={1} p={1}>
          <Typography component="p" align="center" className={classes.license}>
            <a rel="license" href="http://creativecommons.org/licenses/by-nc/4.0/">
              <img
                alt="Creative Commons Licence"
                style={{ borderWidth: '0' }}
                src="https://i.creativecommons.org/l/by-nc/4.0/88x31.png"
              />
            </a>
            <br />
            {`This work is licensed under a `}
            <a rel="license" href="http://creativecommons.org/licenses/by-nc/4.0/">
              Creative Commons Attribution-NonCommercial 4.0 International License
            </a>
            .
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};
