import firebase from 'firebase';
import { Strip } from '../modules/Comics';

export async function getStrips(): Promise<any> {
  const storageRef = firebase.storage().ref('comics');

  const allComics = await storageRef.listAll();

  console.log(allComics);
  return allComics.items;
}

export async function getStripObject(imageRef: any): Promise<Strip> {
  const storageRef = firebase.storage().ref();

  const imgRef = storageRef.child(imageRef.location.path);
  const imagePath = await imgRef.getDownloadURL();

  console.log(imageRef);

  const date = imageRef.name.substring(0, 10);
  const title = imageRef.name.substring(11);

  return {
    path: imagePath,
    date: date,
    title: title,
  };
}
