import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

export async function initFirebase() {
  const firebaseConfig = {
    apiKey: 'AIzaSyDbwipmTCjTkjS_lCJgAxMX7lA6TUjSZIo',
    authDomain: 'unstaged-comic.firebaseapp.com',
    databaseURL: 'https://unstaged-comic.firebaseio.com',
    projectId: 'unstaged-comic',
    storageBucket: 'unstaged-comic.appspot.com',
    messagingSenderId: '382729755047',
    appId: '1:382729755047:web:217f69aca531c73d9b7e82',
    measurementId: 'G-08BSVCM7XF',
  };

  firebase.initializeApp(firebaseConfig);
}
